<template>
    <iframe
        v-if="item"
        :src="item.url"
        width="100%"
        height="100%"
        frameborder="0"
    ></iframe>
</template>

<script>
import mediaViewMixin from '@apps/media/mixins/mediaViewMixin'

export default {
    name: 'FilePdfView',
    mixins: [ mediaViewMixin ]
}
</script>

<style lang=scss>

</style>
